<template>
  <v-container class="container">
    <v-card elevation="2" shaped>
      <v-card-title class="text-h5">帮助</v-card-title>
      <v-card-text>
        <p class="text-body-1">
          <ul>
            <li>能相互替代的课程放入同一个「课程组」中（比如课程编号相同只是老师不同的课程，或可以相互/高阶替代的课程）</li>
          </ul>
        </p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Help",
};
</script>

<style lang="stylus" scoped>
.container {
  margin-top: 40px;
}
</style>